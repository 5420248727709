import React, {useEffect} from "react"
import NumberFormat from 'react-number-format';
import {ListItemIcon} from "@material-ui/core";
import {GenerateImagePath} from "../services/GenerateImagePath";
import GenerateProductList from "../services/GenerateProductListService";
import KnxGenerateProjectService from "../services/knx_ausstattung/GenerateProjectService";
import EasyGenerateProjectService from "../services/easy_ausstattung/GenerateProjectService";
import KonventionellGenerateProjectService from "../services/konventionell_ausstattung/GenerateProjectService";
import products from "../data/products.json";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import gaEventTracker from "../services/gaEventTracker";
import gaPageTracker from "../services/gaPageTracker";

export default function StepFiveComponent({project, updateProject, updateQueryParams, isExpert}){
    useEffect(() => {
        document.title = 'Hager easyplan - Schritt 5';
        updateQueryParams('step_5', document.title);
        gaPageTracker((isExpert ? '/expert' : '/customer') + '/page_5');
    }, []);

    function useProjectService(projectIn){
        if(project.generatedProject !== undefined){
            return project;
        }
        switch(projectIn.projectType){
            case "knx":
                return KnxGenerateProjectService(projectIn, products);
            case "easy":
                return EasyGenerateProjectService(projectIn, products);
            case "konventionell":
                return KonventionellGenerateProjectService(projectIn, products);
            default:
                return project;
        }
    }
    const currentProject = useProjectService(project);

    var productListAndFilters = GenerateProductList(currentProject, undefined, undefined, true);

    function generateGroupList(){
        const list = [];
        productListAndFilters.productList.forEach( g => {
               const subHeader = getCategorieSubHeader(g.name);
               list.push(<div className={"group"} key={g.name}>
                   <div className={"groupHeader row"} style={{position: "relative", padding: "15px 0"}}>
                       <div className={"section-title"} style={{position: "absolute", left: "0"}}>{g.name}</div>
                       <div className={"col-1"}></div>
                       <div className={"col-20"}>
                           <div className="tableHeader row" style={{margin: 0}}>
                               <div className="col-6">
                                   <div className={"row"}>
                                       <div className={"col-2"}></div>
                                       <div className={"text-right col-4"}></div>
                                       <div className={"col-1"}>Art. Nr.</div>
                                   </div>
                               </div>
                               <div className="text-right col-2">UVP</div>
                               <div className="text-right col-2">Anzahl</div>
                               <div className="text-right col-2">Summe</div>
                           </div>
                       </div>
                   </div>
                   <div className={subHeader !== "" ? "groupSubHeader": "groupSubHeader hidden"}>{subHeader}</div>
                   <div className={"groupContent step5"}>
                       {
                           generateProductListForGroup(g.products)
                       }
                   </div>
               </div>)
        });

        return list;
    }
    function getCategorieSubHeader(name) {
        let retVal = "";
        switch (name) {
            case "Rahmen": retVal = "Hinweis: „Die Rahmenausrichtung ist auf die örtliche Gegebenheit mit dem Elektrofachpartner abzustimmen“"; break;
            default: break;
        }
        return retVal;
    }
    const onSelectProducts = (key, value) => {
        const list = (project.selectedAlternatives !== undefined) ? project.selectedAlternatives : {};
        list[key] = value;
        updateProject({...project, selectedAlternatives: list});
    }
    function generateProductListForGroup(products){
        const list = [];
        // console.log('products', products)
        products.forEach(pa => {pa.productSwitch.forEach( (pb, pbidx) => {
            list.push( <div className={"row productSwitchGroup" + (pbidx>0?" pb_grayed":"")} key={pb.key + pb.value}>
                {pbidx==1?<div className={"pb_variante"}>Variante</div>:null}
                <div className={"col-1"}>
                    <ListItemIcon style={{fontSize: "inherit", minWidth: "30px", marginTop: "8px"}} onClick={event => onSelectProducts(pb.key, pb.value)}>
                        {((project.selectedAlternatives === undefined && pb.value === undefined) || (project.selectedAlternatives !== undefined && project.selectedAlternatives[pb.key] === pb.value)) ? <RadioButtonCheckedIcon style={{width: "o.6em", height: "o.7em"}}/> : <RadioButtonUncheckedIcon />}
                    </ListItemIcon>
                </div>
                <div className={"col-20"}>
                    {pb.products.map((p, pidx) =>
                        <div className={"row productSwitchRow"} key={p.Artikelnummer}>
                            <div className="col-6" key={p.Artikelnummer}>
                                <div className={"row"} style={{maxHeight: "50px"}}>
                                    <div className={"col-1"} style={{minWidth: "50px"}}>
                                        <img src={GenerateImagePath.getPath(p.Artikelnummer)} alt=""
                                             style={{maxWidth: "50px", maxHeight: "50px"}}/>
                                    </div>
                                    <div className={"col-10 productName"} style={{padding: "0 30px"}}>{p.Bezeichnung}</div>
                                    <div className={"col-1 artNr"} style={{padding: "0 30px"}}>{p.Artikelnummer}</div>
                                </div>
                            </div>
                            <div className="text-right col-2"><NumberFormat value={(p.Preis / 100)}
                                                                            displayType={'text'} suffix={" €"}
                                                                            thousandSeparator={"."}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            decimalSeparator={","}/></div>
                            <div className="text-right col-2">{Object.keys(pb).includes('counts') ? pb.counts[pidx] : pa.count}</div>
                            <div className="text-right col-2"><NumberFormat value={(p.Preis / 100) * (Object.keys(pb).includes('counts') ? pb.counts[pidx] : pa.count)}
                                                                            displayType={'text'} suffix={" €"}
                                                                            thousandSeparator={"."}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            decimalSeparator={","}/></div>
                        </div>
                    )
                }</div>
            </div>);
        })})
        return list.length > 0 ? list : <div className={"row"}>Keine Produkte</div>
    }

    return <>
        <div className={"section-container"} style={{paddingTop: '0'}}>
            <div className={"section-title"}>Produktvarianten</div>
            <div className={"section-sub-title"}>Die Umsetzung Ihres Projekts ist mit verschiedenen Produktvarianten möglich.<br/>Bitte wählen Sie die für Ihre Bedürfnisse passende Variante aus der untenstehenden Auswahl.</div>
            {generateGroupList()}
        </div>
    </>;
}
