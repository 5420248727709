

export default function getProducts(room, austattung_produkt, selectedAlternatives, totalReglerRt = 0, totalReglerSmart = 0){

    const productList = {list:[], listPro: [], neededSmart: 0, neededRt: 0};

    let countVentil = 0;
    if (room.selectedEquipment["heizen_heizkoerper"] !== undefined && Boolean(room.selectedEquipment["heizen_heizkoerper"]) && room.heatingCount) {
        countVentil += room.heatingCount;
    }
    if (room.selectedEquipment["heizen_heizkoerper"] !== undefined && Boolean(room.selectedEquipment["heizen_heizkoerper"]) && room.floorheatingCount && !room.selectedEquipment["heizen_fussbodenheizung"]) {
        countVentil += room.floorheatingCount;
    }
    if (room.selectedEquipment["heizen_fussbodenheizung"] !== undefined && Boolean(room.selectedEquipment["heizen_fussbodenheizung"])) {
        countVentil+= room.floorheatingCount;
    }
    let addRTtAktor = false;

    if(
        ((room.selectedEquipment["heizen_heizkoerper"] !== undefined
        && Boolean(room.selectedEquipment["heizen_heizkoerper"]))
        || (room.selectedEquipment["heizen_fussbodenheizung"] !== undefined
        && Boolean(room.selectedEquipment["heizen_fussbodenheizung"])))
        && austattung_produkt["rt-regler"] !== undefined
        && austattung_produkt["rt-w"] !== undefined
        && austattung_produkt["rt-aktor"] !== undefined
        && (room.heatingCount > 0 || room.floorheatingCount > 0)
    ){
        productList.list.push({
            product: austattung_produkt["rt-regler"],
            count: 1
        });
        // productList.list.push({
        //     product: austattung_produkt["r-1f"],
        //     count: 1
        // });
        productList.list.push({
            product: austattung_produkt["rt-w"],
            count: 1
        });
        productList.listPro.push({
            product: austattung_produkt["rt-regler"],
            count: 1,
            selectedAccessories: [austattung_produkt["rt-w"]],
        });
        addRTtAktor = true;
    }

    let needed = Math.ceil(countVentil / 6);
    if (countVentil > 6 * needed) { needed += 1; }
    if(
        room.selectedEquipment["heizen_fussbodenheizung"] !== undefined
        && Boolean(room.selectedEquipment["heizen_fussbodenheizung"])
        && austattung_produkt["domovea-basic"] !== undefined
        && austattung_produkt["heizen-smart-aktor"] !== undefined
        && room.floorheatingCount > 0
    ){
        addRTtAktor = false;
        needed = Math.ceil((totalReglerSmart + 1) / 6);
        if ((totalReglerSmart + 1) > (needed * 6)) {
            // console.log('totalReglerSmart', totalReglerSmart, 'needed', needed, totalReglerSmart + 1, needed * 6)
            productList.list.push({
                product: austattung_produkt["heizen-smart-aktor"],
                count: 1
            });
            productList.listPro.push({
                product: austattung_produkt["heizen-smart-aktor"],
                count: 1,
                selectedAccessories: [],
            });
        }
        productList.neededSmart = countVentil;
    }

    if(addRTtAktor){
        needed = Math.ceil((totalReglerRt + 1) / 6);
        if ((totalReglerRt + 1) > (needed * 6)) {
            // console.log('totalReglerRt', totalReglerRt, 'needed', needed, totalReglerRt + 1, needed * 6)
            productList.list.push({
                product: austattung_produkt["rt-aktor"],
                count: 1
            });
            productList.listPro.push({
                product: austattung_produkt["rt-aktor"],
                count: 1,
                selectedAccessories: [],
            });
        }
        productList.neededRt = countVentil;
    }

    if(
        countVentil > 0 && false
        && austattung_produkt["rt-ventil-1"] !== undefined
    ){
        const altList = [
            {
                key: "rtVentil1",
                value: undefined,
                products: [austattung_produkt["rt-ventil-1"]]
            },
            {
                key: "rtVentil1",
                value: "rtVentil2",
                products: [austattung_produkt["rt-ventil-2"]]
            }
        ];
        if(
            selectedAlternatives !== undefined
            && selectedAlternatives["rtVentil1"] !== undefined
        ) {
            productList.list.push({
                product: austattung_produkt["rt-ventil-2"],
                count: countVentil,
                alt: altList
            });
            productList.listPro.push({
                product: austattung_produkt["rt-ventil-2"],
                count: countVentil,
                selectedAccessories: [],
            });
        }else{
            productList.list.push({
                product: austattung_produkt["rt-ventil-1"],
                count: countVentil,
                alt: altList
            });
            productList.listPro.push({
                product: austattung_produkt["rt-ventil-1"],
                count: countVentil,
                selectedAccessories: [],
            });
        }
    } else if (countVentil > 0 && false && austattung_produkt["rt-ventil-2"] !== undefined) {
        productList.list.push({
            product: austattung_produkt["rt-ventil-2"],
            count: countVentil,
        });
        productList.listPro.push({
            product: austattung_produkt["rt-ventil-2"],
            count: countVentil,
            selectedAccessories: [],
        });
    }
    // console.log('returning heizung products', productList, countVentil)

    return productList;
}
