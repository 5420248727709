
import GetAusstattungProduktObj from "../AusstattungProduktService";
import getRauchmelder from "./AddRauchmerlder"
import getBewegungsmelder from "./AddBewegungsmelder"
import getBinaereingang from "./AddBinaereingang"
import getSteckdosen from "./AddSteckdosen"
import getSteckdosenAufputz from "./AddSteckdosenAufputz"
import getSteckdosenUsb from "./AddSteckdosenUsb"
import getSteckdosenRahmen from "./AddSteckdosenRahmen"
import getTelefonDatenanschluss from "./AddTelefonDatenanschluss"
import getTelefonanschluss from "./AddTelefonanschluss"
import getRadioDatenSat from "./AddRadioDatenSat"
import getRadioDatenKabel from "./AddRadioDatenKabel"
import getAnschlussdosenRahmen from "./AddAnschlussdosenRahmen"
import getTuerkommunikation from "./AddTuerkommunikation"
import getSound from "./AddSound"
import getHeizungRaum from "./AddHeizungRaum"
import getHeizungUV from "./AddHeizungUV"
import getAktoren from "./AddAktoren"
import getTaster from "./AddTaster"
import getServer from "./AddServer"
import getWeatherStation from "./AddWeatherStation"
import countActuators from "../ActuatorsCounterService";

export default function GenerateProjectService(projectIn, productList){

    const generatedProject = {
        code: null,
        name: projectIn.name,
        selectedAlternatives: (projectIn.selectedAlternatives !== undefined) ? projectIn.selectedAlternatives : {},
        floors: []
    };
    let building = {
        name: projectIn.name,
        type: projectIn.type,
        floors: []
    };
    let systemProductList = [];
    let systemProductListPro = [];
    let addSpannung = false;
    let totalReglerRt = 0;
    let totalReglerSmart = 0;
    projectIn.floors.forEach( floorIn => {
        const floor = {
            id: floorIn.id,
            name: floorIn.name,
            type: floorIn.type,
            rooms: []
        };
        const floorPro = {
            name: floorIn.name,
            type: floorIn.type,
            rooms: []
        }
        floorIn.rooms.forEach(roomIn => {
            const room = {
                id: roomIn.id,
                name: roomIn.name,
                type: roomIn.type,
                products: []
            }
            const roomPro = {
                name: roomIn.name,
                type: roomIn.type,
                devices: []
            }
            const austattung_produkt = GetAusstattungProduktObj(projectIn, roomIn);
            let productListInner = [];
            let productListInnerPro = [];

            const listA = [
                getRauchmelder(roomIn, austattung_produkt),
                getBewegungsmelder(roomIn, austattung_produkt, projectIn.selectedAlternatives),
                getSteckdosen(roomIn, austattung_produkt),
                getSteckdosenAufputz(roomIn, austattung_produkt),
                getSteckdosenUsb(roomIn, austattung_produkt),
                getSteckdosenRahmen(roomIn, austattung_produkt),
                getTelefonDatenanschluss(roomIn, austattung_produkt),
                getTelefonanschluss(roomIn, austattung_produkt),
                getRadioDatenSat(roomIn, austattung_produkt),
                getRadioDatenKabel(roomIn, austattung_produkt),
                getAnschlussdosenRahmen(roomIn, austattung_produkt),
            ];
            listA.forEach( listA => {
                productListInner = productListInner.concat(listA);
                listA.forEach( lb => {
                    productListInnerPro.push({
                        product: lb.product,
                        count: lb.count,
                        selectedAccessories: [],
                    });
                });
            });

            const tasterFull = getTaster(roomIn, austattung_produkt, projectIn.selectedAlternatives);
            if(tasterFull.list.length > 0){
                addSpannung = true;
                productListInner = productListInner.concat(tasterFull.list);
                productListInnerPro = productListInnerPro.concat(tasterFull.listPro);
            }
            const bin = getBinaereingang(roomIn, austattung_produkt);
            if(bin.length > 0){
                addSpannung = true;
                systemProductList = systemProductList.concat(bin);
            }
            const heizungFull = getHeizungRaum(roomIn, austattung_produkt, projectIn.selectedAlternatives, totalReglerRt, totalReglerSmart);
            // console.log('roomIn', roomIn, heizungFull)
            if(heizungFull.list.length > 0){
                addSpannung = true;
                // systemProductList = systemProductList.concat(heizungFull.list);
                // systemProductListPro = productListInnerPro.concat(heizungFull.listPro);
                totalReglerRt += heizungFull.neededRt;
                totalReglerSmart += heizungFull.neededSmart;
                // console.log('totalReglerRt', totalReglerRt, heizungFull.neededRt, 'totalReglerSmart', totalReglerSmart, heizungFull.neededSmart)
            }

            productListInner.forEach( p => {
                const productTmp = room.products.find(pi => pi.Artikelnummer.toString() === p.product.toString());
                if(productTmp === undefined){
                    const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if(pp === undefined){
                        return;
                    }
                    const productToPush = {...pp, count: p.count};
                    if(p.alt !== undefined){
                        const nlist = p.alt.map(pa => {
                            const altList = [];
                            pa.products.forEach(paPro => {
                                const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                if(pp === undefined){
                                    return;
                                }
                                altList.push(ppa);
                            })
                            pa.products = altList;
                            return pa;
                        });
                        productToPush.productSwitch = nlist;
                    }
                    room.products.push(productToPush);
                }else {
                    productTmp.count += p.count;
                }
            });
            productListInnerPro.forEach( p => {
                const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                if(pp2 === undefined){
                    return;
                }
                let selectedAccessoriesList = [];
                p.selectedAccessories.forEach( ap => {
                    const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString());
                    if(pp3 !== undefined){
                        selectedAccessoriesList.push(pp3["Gtin"]);
                    }
                });
                const productToPush = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList};
                for (let i = 0; i < p.count; i++) {
                    roomPro.devices.push(productToPush);
                }
            });
            floor.rooms.push(room);
            floorPro.rooms.push(roomPro);
        });
        generatedProject.floors.push(floor);
        building.floors.push(floorPro);
    });
    const austattung_produkt = GetAusstattungProduktObj(projectIn, projectIn.floors[0].rooms[0]);
    // console.log('totalreglerSmart', totalReglerSmart, austattung_produkt["heizen-smart-aktor"], 'rt', totalReglerRt, austattung_produkt["rt-aktor"], systemProductList)
    if (totalReglerSmart > 0) {
        totalReglerSmart += totalReglerRt;
        totalReglerRt = 0;
        const found = systemProductList.filter(i => i.product === austattung_produkt["heizen-smart-aktor"]);
        // console.log('found', found, totalReglerSmart, totalReglerRt)
        if (found && found.length > 0) {
            if (found.length * 6 < totalReglerSmart) {
                systemProductList.push({
                    product: austattung_produkt["heizen-smart-aktor"],
                    count: 1
                });
            }
        } else {
            let needed = Math.ceil(totalReglerSmart / 6);
            if (totalReglerSmart > 6 * needed) { needed += 1; }
            // console.log('needed', needed)
            systemProductList.push({
                product: austattung_produkt["heizen-smart-aktor"],
                count: needed
            });
        }
    }
    if (totalReglerRt > 0) {
        const found = systemProductList.filter(i => i.product === austattung_produkt["rt-aktor"]);
        if (found && found.length > 0) {
            if (found.length * 6 < totalReglerRt) {
                systemProductList.push({
                    product: austattung_produkt["rt-aktor"],
                    count: 1
                });
            }
        } else {
            let needed = Math.ceil(totalReglerRt / 6);
            if (totalReglerRt > 6 * needed) { needed += 1; }
            systemProductList.push({
                product: austattung_produkt["rt-aktor"],
                count: needed
            });
        }
    }
    // console.log('systemproductlist', systemProductList)
    systemProductList = systemProductList.concat(getSound(projectIn, austattung_produkt));
    systemProductList = systemProductList.concat(getHeizungUV(projectIn, austattung_produkt));
    const akt = getAktoren(projectIn, austattung_produkt, projectIn.selectedAlternatives);
    // console.log('akt', akt)
    if(akt.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(akt);
    }
    const weatherStation = getWeatherStation(projectIn, austattung_produkt, projectIn.selectedAlternatives);
    if(weatherStation.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(weatherStation);
    }
    const serverFull = getServer(projectIn, austattung_produkt, projectIn.selectedAlternatives);
    if(serverFull.list.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.filter(i => !serverFull.removeKeys.includes(i.product));
        systemProductList = systemProductList.concat(serverFull.list);
    }
    const getTuerko = getTuerkommunikation(projectIn, austattung_produkt);
    systemProductList = systemProductList.filter(i => !getTuerko.removeList.includes(i.product));
    systemProductList = systemProductList.concat(getTuerko.list);
    if(addSpannung){
        systemProductList.push(
            {
                product: austattung_produkt["spannung"],
                count: 1
            }
        );
    }
    const countAktors = countActuators(systemProductList, projectIn, austattung_produkt);
    if (countAktors && countAktors.removeList && countAktors.removeList.length) {
        countAktors.removeList.forEach(removeKey => {
            systemProductList = systemProductList.filter(i => i.product !== removeKey);
        });
    }
    if (countAktors && countAktors.list && countAktors.list.length) {
        systemProductList = systemProductList.concat(countAktors.list);
    }
    // console.log('systemproductlist', systemProductList)
    systemProductListPro = [];
    if(systemProductList.length > 0){
        const uv = {
            id: "Unterverteilung", //uuidv4(), todo
            name: "Unterverteilung",
            type: "d0aea507-04d1-4cd2-95b1-7c3f94-a0d53a6c",
            products: []
        }
        const uvPro = {
            name: "Unterverteilung",
            type: "d0aea507-04d1-4cd2-95b1-7c3f94-a0d53a6c",
            devices: []
        }
        systemProductList.forEach( p => {
            systemProductListPro.push({
                product:  p.product,
                count:  p.count,
                selectedAccessories: [],
            });
            const productTmp = uv.products.find(pi => pi.Artikelnummer.toString() === p.product.toString().replace('dali-option|',''));
            if(productTmp === undefined){
                const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString().replace('dali-option|',''));
                if(pp === undefined){
                    return;
                }
                const productToPush = {...pp, count: p.count};
                if(p.alt !== undefined){
                    const nlist = p.alt.map(pa => {
                        const altList = [];
                        pa.products.forEach(paPro => {
                            const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                            if(pp === undefined){
                                return;
                            }
                            altList.push(ppa);
                        })
                        pa.products = altList;
                        return pa;
                    });
                    productToPush.productSwitch = nlist;
                }
                uv.products.push(productToPush);
            }else {
                productTmp.count += p.count;
            }
        });
        // console.log('systemproductlistpro', systemProductListPro)
        systemProductListPro.forEach( p => {
            const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString().replace('dali-option|',''));
            if(pp2 === undefined){
                return;
            }
            let selectedAccessoriesList = [];
            p.selectedAccessories.forEach( ap => {
                const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString().replace('dali-option|',''));
                if(pp3 !== undefined){
                    selectedAccessoriesList.push(pp3["Gtin"]);
                }
            });
            const productToPush = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList};
            for (let i = 0; i < p.count; i++) {
                uvPro.devices.push(productToPush);
            }
        });
        generatedProject.floors[0].rooms.push(uv);
        building.floors[0].rooms.push(uvPro);
    }
    let projectTypes = {
        knx: "KNX",
        easy: "HAGER_KNX_EASY",
        konventionell: "CONVENTIONAL"
    };

    const projectPro = {
        name: projectIn.projectName,
        type: projectTypes[projectIn.projectType],
        buildings: [building]
    };
    return {...projectIn, generatedProject: generatedProject, projectPro: projectPro}

}
